<template>
    <div class="col-12 d-flex product-details-border position-relative pe-0">
        <div class="row w-100 pe-lg-0 me-1 py-2">
            <div class="col-md-3 col-12">
                <div class="mb-1">
                    <label class="form-label" for="product_id">Product</label>
                    <AsyncSelect
                        placeholder="Select Product"
                        v-model="data.product"
                        :api-service="fetchProductList"
                        :format-label="option => option.text"
                        :additional-query="additionalQuery"
                        :additional-option="additionalOptions"
                        label="text"
                        ref="productField"
                        :disabled="isUpdate"
                    />
                </div>
            </div>
            <div class="col-md-2 col-12">
                <div class="mb-1">
                    <label class="form-label" for="batch_number">
                        Batch Number <span class="text-danger">*</span>
                    </label>
                    <vField
                        v-model="data.batch_number"
                        name="batch_number"
                        type="text"
                        id="batch_number"
                        class="form-control"
                    />
                    <span class="text-danger" v-if="batchError && batchError !== ''">{{ batchError }}</span>
                </div>
            </div>

            <div class="col-md-2 col-12">
                <div class="mb-1">
                    <label class="form-label" for="exp_date">
                        Expiry Date <span class="text-danger">*</span>
                    </label>
                    <vField
                    v-model="data.exp_date"
                    name="exp_date"
                    id="exp_date"
                    type="month"
                    class="form-control"
                    />
                    <span class="text-danger" v-if="expDateError && expDateError !== ''">{{ expDateError }}</span>
                </div>
            </div>
            <div class="col-md-1 col-12">
                <div class="mb-1">
                    <label class="form-label" for="rate">S. Rate</label>
                    <vField
                        v-model="data.selling_rate"
                        name="rate"
                        type="number"
                        class="form-control text-right px-1"
                    />
                </div>
            </div>
            <div class="col-md-1 col-12">
                <div class="mb-1">
                    <label class="form-label" for="rate">P. Rate</label>
                    <vField
                        v-model="data.rate"
                        name="rate"
                        type="number"
                        class="form-control text-right px-1"
                        @keyup="validatePurchaseRate"
                    />
                    <span class="text-danger" v-if="rateError && rateError !== ''">{{ rateError }}</span>
                </div>
            </div>
            <div class="col-md-1 col-12">
                <div class="mb-1">
                    <label class="form-label" for="quantity">Quantity</label>
                    <input
                        v-model="data.quantity"
                        name="quantity"
                        type="number"
                        class="form-control text-right"
                        ref="qtyField"
                        @keyup.enter="handleAddProduct"
                        @blur="validateQty"
                        :disabled="disableQtyField"
                    />
                    <span class="text-danger" v-if="quantityError && quantityError !== ''">{{ quantityError }}</span>
                </div>
            </div>

            <div class="col-md-2 col-12">
                <div class="mb-1">
                    <label class="form-label" for="quantity">Amount</label>
                    <vField
                        readonly
                        v-model="amount"
                        name="amount"
                        type="number"
                        class="form-control text-right"
                    />
                </div>
            </div>

            <div class="col-md-2 col-12">
                <div class="mb-1">
                    <label class="form-label" for="discount-percent">Discount Percent</label>
                    <input
                        v-model.trim="data.discount_percent"
                        type="number" class="form-control"
                        placeholder="Discount(%)"
                        :min="0"
                        id="discount-percent"
                        @keyup.enter="handleAddProduct"
                    >
                </div>
            </div>

            <div class="col-md-2 col-12">
                <div class="mb-1">
                    <label class="form-label" for="vat">VAT Rate</label>
                    <input
                        v-model.trim="data.vat"
                        type="number" class="form-control"
                        placeholder="VAT(%)"
                        :min="0"
                        id="discount-percent"
                        @keyup.enter="handleAddProduct"
                    >
                </div>
            </div>

            <div class="col-md-2 col-12">
                <div class="mb-1">
                    <label class="form-label" for="vat">VAT Amount</label>
                    <input
                        v-model.trim="data.vat_amount"
                        type="number" class="form-control"
                        placeholder="VAT Amount"
                        :min="0"
                        id="discount-percent"
                        @keyup.enter="handleAddProduct"
                    >
                </div>
            </div>

            <div class="col-md-2 col-12">
                <div class="mb-1">
                    <label class="form-label" for="total_amount">Total Amount</label>
                    <vField
                        v-model="totalAmount"
                        readonly
                        name="total_amount"
                        type="number"
                        class="form-control text-right"
                        @keyup.enter="handleAddProduct"
                    />
                </div>
            </div>

            <div class="col-md-2 col-12">
                <div class="mb-1">
                    <label class="form-label" for="mfn_date">
                        Manufacturing Date
                    </label>
                    <vField
                        v-model="data.mfn_date"
                        name="mfn_date"
                        id="mfn_date"
                        type="month"
                        class="form-control"
                        @keyup.enter="handleAddProduct"
                    />
                </div>
            </div>

             <div class="col-md-2 col-12 mt-2">
                <button
                    class="btn btn-primary"
                    @click="handleAddProduct"
                >
                  Add
                </button>
             </div>
        </div>
    </div>
</template>

<script setup>
import {computed, ref} from "@vue/runtime-core";
import {inject, watch} from "vue";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handlePurchaseAndSales from "@/services/modules/purchase";
import {useRoute} from "vue-router";
import {floor, isEmpty, trim} from "lodash";

const props = defineProps({
    isUpdate: {
      type: Boolean,
      default: false
    }
})
const singleData = {
    product: null,
    product_name: '',
    product_id: null,
    batch_number: '',
    description: '',
    mfn_date: null,
    exp_date: null,
    selling_rate: 0.00,
    rate: 0.00,
    quantity: 0,
    discount_percent: null,
    vat: 0,
    vat_amount: null,
    g_total: 0,
}

const additionalQuery = ref({
  product_type: "finished_goods"
})

const showError = inject('showError');
const {fetchProductList, fetchProductStock} = handlePurchaseAndSales()
const route = useRoute();
const $emit = defineEmits(['handleProductAdd'])
const vatAmount = ref(0);
const batchError = ref('')
const expDateError = ref('')
const rateError = ref('')
const quantityError = ref('')
const qtyField = ref(null)
const productField = ref(null)
const updateIndex = ref(null)
const data = ref({...singleData})
const companyQuery = `?company_id=${route.params.companyId}`;

const amount = computed(() => (data.value.rate * data.value.quantity).toFixed(4))

const vatPercent = computed(() => data.value.vat);

const vatAmountInput = computed(() => data.value.vat_amount)

const selectedProduct = computed(() => data.value.product)

const disableQtyField = computed(() => data.value.hasOwnProperty('can_edit_qty') && !data.value.can_edit_qty );

const amountAfterDiscount = computed(() => {
  const discountAmount = data.value.discount_amount === '' ? 0 : parseFloat(data.value.discount_amount);
  const amountValue = parseFloat(amount.value)
  if(!discountAmount || discountAmount === 0) {
    return amountValue;
  }
  return amountValue - discountAmount
})

const additionalOptions = computed(() => {
  if (route.params.purchaseId && data.value.product_id && data.value.product) {
    return [
      {
        id: data.value.product_id,
        text: data.value.product.name,
      }
    ]
  }
});

const discountPercent = computed(() => data.value.discount_percent);

const totalAmount = computed(() => {
    if(data.value.vat === 0 && vatAmount.value === 0) {
        return parseFloat(amountAfterDiscount.value)
    }
    
    return parseFloat(amountAfterDiscount.value) + parseFloat(vatAmount.value)
})


watch(selectedProduct, (newValue) => {
    if(newValue && newValue.id) {
        fetchProductStock(newValue.id, `${companyQuery}&skip_stock_check=1`).then((res) => {
            if(res.data) {
                data.value.product_id = newValue.id;
                data.value.selling_rate = res.data.sales_rate ?  res.data.sales_rate : 0;
                data.value.product_name = newValue.text;
                return;
            }
            data.value.product_id = null;
            data.value.selling_rate = 0;
            data.value.product_name = "";
        })
            .catch(() => {})
    }
})

watch(vatPercent, () => {
    if(data.value.vat === 0 && !data.value.vat_amount) {
        vatAmount.value = 0
        return;
    }

    vatAmount.value = (data.value.vat/100 * amountAfterDiscount.value).toFixed(4)
})

watch(vatAmountInput, () => {
    vatAmount.value = data.value.vat_amount ?? 0
})

watch(vatAmount, (newValue, oldValue) => {
  if(! isNaN(oldValue) || !route.params.purchaseId){
    data.value.vat_amount  = newValue;
  }
})

watch(discountPercent, (value) => {
  let discount = 0;
  const amountValue = parseFloat(amount.value);
  value = parseFloat(value);

  if(value && value > 0 && amountValue > 0) {
    discount = amountValue * (value/100);
  }
  data.value.discount_percent = value;
  data.value.discount_amount = discount.toFixed(4);
})
watch(totalAmount, (newValue) => {
    data.value.g_total = newValue
})

const focusProductSelect = () => {
    productField.value.dropdownRef.searchEl.focus()
}

const validateData = () => {

    batchError.value = ""
    expDateError.value = ""
    rateError.value = ""
    quantityError.value = ""

    let errorsFound = false;

    if(isEmpty(trim(data.value.batch_number))) {
        batchError.value = "Batch number is required"
        errorsFound = true;
    }

    if(isEmpty(trim(data.value.exp_date))) {
        expDateError.value = "Expiry date is required"
        errorsFound = true;
    }

    if(!data.value.rate) {
        rateError.value = "Purchase must be more than 0"
        errorsFound = true;
    }

    if(!validateQty()) {
        return errorsFound;
    }

    if(!data.value.quantity) {
        quantityError.value = "Quantity must be more than 0"
        errorsFound = true;
    }

    return errorsFound;
}

const validatePurchaseRate = () => {
    if (data.value.selling_rate < data.value.rate) {
        showError("Purchase rate cannot be more than selling rate");
        data.value.rate = data.value.selling_rate
        return false;
    }

    return true;
}

const validateQty = () => {
    let quantity = Number(data.value.quantity)

    if (! Number.isInteger(quantity)) {
        quantityError.value = "The quantity must be an Integer";
        data.value.quantity = floor(data.value.quantity)
        qtyField.value.focus();
        return false;
    }

    return true;
}

const handleAddProduct = () => {

    if(validateData()) {
        return;
    }
    $emit('handleProductAdd',  {...data.value}, updateIndex.value);
    data.value = { ...singleData };
    focusProductSelect();

    if(updateIndex.value !== null && updateIndex.value !== undefined) {
        updateIndex.value = null;
    }
}

const setData = (object) => {
    if(object) {
        data.value = { ...object };
    }
}

defineExpose({updateIndex, setData})

</script>