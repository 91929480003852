<template>
  <div class="card" style="min-height: calc(100vh - 180px)">
    <div class="bg-blue-light p-1">
      <TitleButton btnTitle="List" :showBtn="true" :showAddNew="false" title="Update Purchase Bill"
                   @onClickCloseButton="navigateToListPage"/>

      <div class="row mt-2 gy-1">
        <div class="col-md-4">
          <input class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                 tabindex="1" type="date" placeholder="" v-model="formData.date">
        </div>
        <div class="col-md-4">
          <v-select placeholder="Select Business" v-model="formData.business_id" :options="business"
                    label="name" :reduce="name => name.id"/>
        </div>
        <div class="col-md-4 d-flex align-items-center justify-content-between">
          <AsyncSelect placeholder="Select Contact" v-model="contactProfile"
                       :disabled="route.query.type === 'purchase-order-bill'"
                       :api-service="fetchContactProfiles" :format-label="formatPatientLabel" class="flex-grow-1"
                       :additional-query="{ type: 'supplier' }"/>
          <span class="filter-search-icon cursor-pointer mx-1" @click="onOpenContactSearchModal">
                        <i class="fas fa-search"></i>
                    </span>
        </div>
        <div class="col-md-4">
          <v-select placeholder="Account Head" v-model="formData.account_head_id" :options="accountPayable"
                    label="name" :reduce="name => name.id"/>
        </div>

        <div class="col-md-4">
          <v-select placeholder="Select Warehouse Location" v-model="formData.location_id"
                    :options="locations" label="text" :reduce="text => text.id"/>
        </div>
        <div class="col-md-4">
          <div class="d-flex align-items-center justify-content-md-end mb-1">
            <div class="input-group input-group-merge invoice-edit-input-group">
              <div class="input-group-text" style="background: #EFEFEF">
                <span>{{ prefix }}-</span>
              </div>
              <input type="number" min="1" class="form-control invoice-edit-input" placeholder=""
                     v-model="serial" readonly>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="px-3">
      <ProductForm @handleProductAdd="handleProductAdd" ref="productForm" :is-update="true"/>
      <ProductsTable :itemDetails="formData.item_details" @removeBill="removeBill" :formRef="productForm"/>
    </div>

    <div class="px-2">
      <div class="row justify-content-end">
        <div class="col-12 col-md-4">
          <div class="row">
            <div class="col-12">
              <div class="mb-1 row">
                <div class="col-sm-4">
                  <label class="col-form-label" for="first-name">Sub total</label>
                </div>
                <div class="col-sm-8">
                  <input v-model="subTotal" readonly type="text" class="form-control text-right">
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="mb-1 row">
                <div class="col-sm-4">
                  <label class="col-form-label" for="first-name">VAT</label>
                </div>
                <div class="col-sm-8">
                  <input v-model="totalVAT" readonly type="text" class="form-control text-right">
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="mb-1 row">
                <div class="col-sm-4">
                  <label class="col-form-label" for="first-name">Total Amount</label>
                </div>
                <div class="col-sm-8">
                  <input v-model="total" readonly type="text" class="form-control text-right">
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="mb-1 row">
                <div class="col-sm-4">
                  <label class="col-form-label" for="discount-amount">Adjustment Amount</label>
                </div>
                <div class="col-sm-8">
                  <input id="discount-amount" v-model.trim="discountAmount" type="number"
                         class="form-control form-control-sm discount-adj ml-auto text-right"
                         placeholder="amount" :min="0" @keyup="updateGrandTotal">
                </div>
              </div>
            </div>
            <div class="col-12" v-if="discountAmount > 0">
              <div class="mb-1 row">
                <div class="col-sm-4">
                  <label class="col-form-label" for="discount-amount">Settlement Account</label>
                </div>
                <div class="col-sm-8">
                  <v-select placeholder="Select Discount Head" v-model="formData.discount_head_id"
                            :options="salesAccountHeads" label="name" :reduce="name => name.id"/>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="mb-1 row">
                <div class="col-sm-4">
                  <label class="col-form-label" for="discount-amount">Net Bill</label>
                </div>
                <div class="col-sm-8">
                  <input id="discount-amount" v-model.trim="grandTotal" type="number"
                         class="form-control form-control-sm discount-adj ml-auto text-right"
                         placeholder="amount" :min="0" @keyup="updateDiscount">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 px-2">
      <div class="mb-1">
        <label class="form-label" for="description">Memo</label>
        <vField as="textarea" name="description" type="number" class="form-control" v-model="formData.description"/>
      </div>
    </div>

    <div class="pb-5 px-2 mt-4">
      <div class="d-flex flex-wrap gap-1 gy-2">
        <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(false)"
                class="btn btn-primary">Update
        </button>
        <button :disabled="productLoader" @click="navigateToListPage"
                class="btn btn-outline-secondary">Cancel
        </button>
      </div>
    </div>
    <GlobalLoader/>
    <ContactDropdownFilter v-if="store.state.isModalOpenThree" @onSearchContact="onSearchContact" type="supplier"
                           :companyRoles="companyRoles"/>
  </div>
</template>

<script setup>
import {computed, inject, onMounted, ref, watch} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import handlePurchase from '@/services/modules/purchase'
import handleCBusinesses from '@/services/modules/businesses'
import handleBusinessesLocations from '@/services/modules/businessesLocations'
import handleContact from '@/services/modules/contact'
import TitleButton from '@/components/atom/TitleButton'

import {editVoucherNumber, generateTxnNumber} from "@/services/utils/voucherNumberGenerator";
import GlobalLoader from "@/components/atom/GlobalLoader.vue";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import {useStore} from "vuex";
import ContactDropdownFilter from "@/components/atom/Contact/ContactDropdownFilter.vue";
import handleRole from "@/services/modules/role";
import ProductForm from "./ProductForm.vue"
import ProductsTable from "./ProductsTable.vue"
import useDate from '@/services/utils/day'
import handleBarcode from "@/services/modules/barcode";
import pdfPrinter from '@/services/utils/pbPdfPrinter';
import handleCompany from "@/services/modules/company";

const router = useRouter()
const route = useRoute()
const store = useStore()

const showError = inject('showError');
const showSuccess = inject('showSuccess');

const {fetchAccountHead, fetchPurchaseBill, ...rest} = handlePurchase()
const {fetchBusinessList} = handleCBusinesses()
const {fetchBusinessLocationsList} = handleBusinessesLocations()
const {fetchContactProfiles} = handleContact()
const {formatPatientLabel} = useAsyncDropdownHelper();
const {fetchCompanyDefaultRoles} = handleRole();
const {fetchBarcode} = handleBarcode();
const {formatDate, currentDate} = useDate()
const {exportToPDF} = pdfPrinter();
const {fetchCompanyInfo} = handleCompany();

const companyId = computed(() => {
  return route.params.companyId
})
const loader = ref(false)
const productLoader = ref(false)
const saveButtonLoader = ref(false)
const saveNewButtonLoader = ref(false)
const accountHeads = ref([])
const business = ref([])
const locations = ref([])
const salesAccountHeads = ref([])
const accountPayable = ref([])
const prefix = ref('')
const serial = ref('')
const companyInfo = ref({});
const discountAmount = ref(null);
const contactProfile = ref(null);
const productForm = ref(null);
const companyRoles = ref([]);
const grandTotal = ref(0);
const formData = ref({
  company_id: companyId,
  contact_profile_id: null,
  account_head_id: null,
  business_id: null,
  location_id: null,
  bill_number: '',
  status: 'active',
  date: '',
  has_item_detail: true,
  description: '',
  account_details: [],
  item_details: [],
})

onMounted(async () => {
  loader.value = true
  let voucher = await generateTxnNumber(`?company_id=${route.params.companyId}&voucher_type=journal_voucher&txn_type=bill_journal`);
  prefix.value = voucher.prefix;
  serial.value = voucher.serial;
  formData.value.date = currentDate();
  const companyQuery = `?company_id=${companyId.value}`

  const accountHeadRes = fetchAccountHead(companyQuery)
  const businessRes = fetchBusinessList(companyQuery)
  const businessLocationRes = fetchBusinessLocationsList(companyQuery)
  const payableRes = rest.fetchAccountPayable(companyQuery)
  const salesAccountHeadsRes = rest.getAccountHeadBySlag("discount_receipt", companyQuery)
  const fetchSingePurchaseBill = fetchPurchaseBill(route.params.purchaseId, companyQuery)

  Promise.all([
    accountHeadRes.then(res => {
      if (res.data) accountHeads.value = res.data
    }),
    businessRes.then(res => {
      if (res.data) business.value = res.data
    }),
    businessLocationRes.then(res => {
      if (res.data) locations.value = res.data
    }),
    fetchCompanyDefaultRoles(companyQuery).then(res => {
      if (res.data) companyRoles.value = res.data
    }),
    payableRes.then(res => {
      if (!res.data) {
        return;
      }

      accountPayable.value = res.data
      formData.value.account_head_id = res.data.length ? res.data[0].id : null
    }),
    salesAccountHeadsRes.then(res => {
      if (res.data) salesAccountHeads.value = res.data
      formData.value.discount_head_id = res.data[0] ? res.data[0].id : null
    }),
    fetchSingePurchaseBill.then(resp => {
      if (!resp.data) {
        return
      }

      let voucher = editVoucherNumber(resp.data.bill_number);
      prefix.value = voucher.prefix;
      serial.value = voucher.serial;
      formData.value = resp.data
      discountAmount.value = resp.data.adjustment_amount
      contactProfile.value = resp.data.contact
      if (resp.data.has_item_detail) {
        formData.value.item_details = resp.data.general.map(item => {
          return {
            ...item,
            product_name: item.product.name,
            g_total: item.total_amount,
            product: {
              ...item.product,
              text: item.product.name
            }
          }
        })
        formData.value.has_item_detail = true
      }

    }),
    fetchCompanyInfo(companyId.value).then(res => {
      if (res) {
        companyInfo.value = res.data
      }
    })
  ])
    .then(() => {
      loader.value = false
    })
    .catch(() => {
      loader.value = false
    })
})

const isItem = computed(() => {
  return formData.value.has_item_detail
})

const subTotal = computed(() => {
  let subTotal = 0
  if (formData.value.has_item_detail) {
    formData.value.item_details.map(item => {
      subTotal += parseFloat((item.quantity * item.rate) - (item.discount_amount ?? 0))
    })
  }
  if (!formData.value.has_item_detail) {
    formData.value.account_details.map(item => {
      if (item.amount) {
        subTotal += parseFloat(item.amount)
      }
    })
  }

  return subTotal.toFixed(4)
})

const totalVAT = computed(() => {
  let vat = 0
  if (formData.value.has_item_detail) {
    formData.value.item_details.map(item => {
      if (item.vat_amount) {
        vat += parseFloat(item.vat_amount)
      }
    })
  }
  if (!formData.value.has_item_detail) {
    formData.value.account_details.map(item => {
      if (item.vat_amount) {
        vat += parseFloat(item.vat_amount)
      }
    })
  }

  return vat.toFixed(4)
})

const total = computed(() => {
  return parseFloat(subTotal.value) + parseFloat(totalVAT.value)
})

const updateGrandTotal = () => {
  grandTotal.value = total.value - parseFloat(discountAmount.value);
}

const updateDiscount = () => {
  discountAmount.value = (total.value - grandTotal.value).toFixed(4);
}

watch(discountAmount, () => {
  formData.value.adjustment_amount = parseFloat(discountAmount.value)
})

watch(total, () => {
  if (discountAmount.value && discountAmount.value !== "") {
    grandTotal.value = total.value - parseFloat(discountAmount.value);
    return;
  }
  grandTotal.value = total.value;
})

watch(contactProfile, (newVal) => {
  if (!newVal) {
    formData.value.contact_profile_id = null;
    return;
  }

  formData.value.contact_profile_id = newVal.id;
})

function navigateToListPage() {

  router.push({
    name: (route.query.type === 'purchase-order-bill')
           ? 'purchase-order-bill' : 'purchase-bill-manager',
    params: route.params, query: route.query
  })
}

function onOpenContactSearchModal() {
  store.state.isModalOpenThree = true;
}

function onSearchContact(contact) {
  contactProfile.value = contact
}

function removeBill(index) {
  formData.value.item_details.splice(index, 1)
}

const goToPrint = (id) => {
  const user = store.state.user.user
  const companyQuery = `?company_id=${route.params.companyId}`;
  let barcodeQuery = "";
  let qrcode = null
  let barcode = null
  let purchaseBill = null

  fetchPurchaseBill(id, companyQuery).then((res) => {
    if (res) {
      purchaseBill = res.data;
      let qrcodeData = JSON.stringify({
        ref_no: purchaseBill.bill_number,
        ref_date: purchaseBill.date,
        created_by: user.name,
        created_date_time: formatDate(new Date())
      })
      barcodeQuery = `?code=${purchaseBill.bill_number}&qrcode=${qrcodeData}`;
    }
  }).then(() => {
    fetchBarcode(barcodeQuery).then((res) => {
      barcode = res.barcode;
      qrcode = res.qrcode
    }).then(() => {
      exportToPDF(companyInfo.value, purchaseBill, barcode, qrcode, user.name, 1)
    })
  }).catch((err) => {
    console.log(err);
  })
}

function handleSubmit(redirect = false) {
  saveNewButtonLoader.value = true

  if (!formData.value.date) formData.value.date = currentDate();

  if (!formData.value.adjustment_amount) {
    formData.value.discount_head_id = null;
  }

  formData.value.bill_number = prefix.value + '-' + serial.value
  let copyFormData = Object.assign({}, formData.value);
  copyFormData.has_item_detail = formData.value.has_item_detail ? 1 : 0
  copyFormData.updateSalePrice = 1;

  if (isItem.value) {
    delete copyFormData.account_details
    copyFormData.item_details = formData.value.item_details
  }

  rest.updateBill(route.params.purchaseId, copyFormData)
    .then(res => {
      saveButtonLoader.value = false
      saveNewButtonLoader.value = false
      if (res.status) {
        showSuccess(res.message)
        navigateToListPage()
        resetForm()
      }
      if (!res.status) {
        showError(res.message)
      }
    })
    .catch(() => {
      saveButtonLoader.value = false
      saveNewButtonLoader.value = false
      productLoader.value = false
    })
}

// have to refactor
async function resetForm() {
  let voucher = await generateTxnNumber(`?company_id=${route.params.companyId}&voucher_type=journal_voucher&txn_type=bill_journal`);
  prefix.value = voucher.prefix;
  serial.value = voucher.serial;
  formData.value.item_details = []
  formData.value.description = '';
  contactProfile.value = null;
  discountAmount.value = null;
}

const handleProductAdd = (item, index) => {
  if (index !== null && Array.isArray(formData.value.item_details) && !!formData.value.item_details[index]) {
    formData.value.item_details[index] = item;
    return;
  }

  formData.value.item_details.push(item);
}

</script>
